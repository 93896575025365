import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import {Link} from "@reach/router";
import Img from "gatsby-image";
import ExternalContent from "../ExternalContent";

class BlogIntro extends React.Component {

    render(){

        return (
            <div className="blog-intro">
                <Row>
                    <Col className="text-center">
                        <Link className="back-link" to="/blog/">All Blogs</Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}}>
                        <h1 dangerouslySetInnerHTML={{__html: this.props.title }} />
                    </Col>
                    <Col xs={{span: 24}} md={{span: 20, offset: 2}} lg={{span: 16, offset: 4}}>
                        <div className="blog-image">
                            { this.props.image != null ?
                                <Img fluid={this.props.image.localFile.childImageSharp.fluid} />
                                : null }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} md={{span: 2}} lg={{span: 4}}>
                        <p className="date">{this.props.date}</p>
                        <p className="author">{this.props.author}</p>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 20}} lg={{span: 16}}>
                        <p className="lead">{this.props.lead}</p>
                        <ExternalContent>
                            <div dangerouslySetInnerHTML={{__html: this.props.content }} />
                        </ExternalContent>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 2}} lg={{span: 4}}>
                        <div className="social-share">
                            <p>Share</p>
                            <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + this.props.title + "&amp;title=" + this.props.title + " - Storyman&amp;summary="} title="Share on LinkedIn" target="_blank" rel="noopener noreferrer">
                                <img src="/linkedin.png" alt="Share on Linkedin" />
                            </a>
                            <a href={"https://twitter.com/intent/tweet?text=" + this.props.title + " - Storyman " + this.props.location.href} title="Share on Twitter" target="_blank" rel="noopener noreferrer">
                                <img src="/twitter.png" alt="Share on Twitter" />
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BlogIntro